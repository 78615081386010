export default {
  heading: 'Lindsey W01 Regular, sans-serif',
  subheading: 'Noto Sans Display',
  body: 'Comfortaa, sans-serif',
  body2: 'Comfortaa, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Cabin Sketch, cursive',
  googleFonts: [
    'Cabin Sketch',
    'Noto Sans Display:100,200,300,400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Comfortaa'
  ],
  customFamilies: ['Lindsey W01 Regular'],
  customUrls: ['https://www.gonation.biz/fonts/lindsey-w01-regular/lindsey-w01-regular.css']
}
