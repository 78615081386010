export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {},
    '.logo': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['70px', '90px', '90px', ''],
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      img: {
        maxHeight: ['40px', '60px', '60px', ''],
        filter: 'unset'
      }
    },

    '.online-ordering': { backgroundColor: 'primary', color: 'white', p: '0.5rem 1rem' },
    '.smallNavMenu .make-a-reservation': { border: 'solid 1px', borderColor: 'currentColor', p: '0.5rem 1rem' },
    '.navItemDropdownMenu': {
      width: 'fit-content',
      textAlign: 'left'
    },

    '.navItem': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },

    '.container': {
      padding: ['2rem 1rem', '', '3rem 1rem', '', '3rem 2rem'],
      position: 'absolute',
      background: 'linear-gradient(rgb(64 64 64 / 75%) 0%, rgba(0, 0, 0, 0.75) 50%, rgb(119 119 119 / 0%) 100%)',

      top: '0rem',
      '.navItemDropdownMenu': {
        width: 'fit-content',
        backgroundColor: 'transparent'
      },
      '.smallNavMenu': {
        // margin: '0rem auto 0rem 2rem',

        '.navItem': {
          textShadow: '1px 1px 8px black',
          color: 'white',
          a: {
            fontSize: '1rem',
            ':hover': {
              textDecoration: 'underline',
              color: 'white'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.5rem 1rem',
      backgroundColor: 'white',
      // width: 'fit-content',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      borderBottom: '1px solid',
      borderColor: 'primary',
      '.navItemDropdownMenu': {
        width: 'fit-content',
        backgroundColor: 'background'
      }
    },
    '.hamburger': {
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'primary',
      backgroundColor: 'rgba(255,255,255, 0.6)',
      display: ['', '', '', ''],
      '> div': {
        backgroundColor: 'primary'
      }
    },

    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      margin: '0rem 1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        backgroundColor: 'primary',
        padding: '0.5rem',
        color: 'light',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    },
    '.hamburgerOpen': {
      borderColor: 'white',
      '> div': {
        backgroundColor: 'light'
      }
    },
    '.navMenuLogo': {
      display: 'none'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        justifyContent: ['center', '', '', 'flex-end'],
        a: {
          color: 'light',
          textAlign: ['', '', '', 'right'],
          fontSize: ['1.2rem', '1.25rem', '1.4rem', '1.4rem', '1.5rem'],
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white'
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      },

      '.navItemDropdownMenu': {
        position: 'static',
        backgroundColor: 'transparent',
        borderTop: 'solid 1px white',
        '.navItem': {
          a: {
            fontSize: '1.2rem'
          }
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh'
    }
  },

  footer: {
    backgroundColor: 'primary',
    borderTop: 'solid 2px',
    borderColor: 'primary',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0.5rem 0.5rem 6rem', '', '0.5rem 3rem'],
    '.image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        width: 'fit-content',
        fontFamily: 'heading'
      }
    },
    '.contactDetails-container': {
      display: 'none'
    },
    '.copyright': {
      width: '50%',
      color: 'white',
      textAlign: 'center'
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',
      a: {
        svg: {
          width: '40px',
          height: '40px',
          padding: '0.5rem',
          border: 'solid 1px white',
          borderRadius: '100px',
          ':hover': {
            backgroundColor: 'grey'
          }
        },
        margin: '0.5rem'
      }
    },
    '.gonationLogo': {
      width: '50%'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
    fontWeight: 'normal',
    letterSpacing: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    textTransform: 'capitalize',
    fontFamily: 'heading',
    color: 'inherit'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'inherit',
    fontFamily: 'body2',
    opacity: '0.8',
    fontWeight: '200'
  },
  text: {
    lineHeight: '1.75',
    p: {
      color: 'inherit',
      lineHeight: '1.75',
      fontSize: ['0.85rem', '0.9rem'],
      textAlign: 'center'
    }
  },

  sideBySide1: {
    backgroundColor: 'background',
    padding: ['1rem', '', '2rem', '3rem'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '80vh'],
      justifyContent: 'flex-start'
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'primary'
    },
    '.text': {
      variant: 'customVariants.text',
      p: {
        textAlign: 'left',
        fontSize: ['0.85rem', '0.9rem']
      }
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  centerBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '90vh',
    color: 'white',
    textAlign: 'center',
    '::before': {
      content: "'▲'",
      color: 'primary',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '4rem',
      margin: '0rem 2rem 2rem',
      transform: 'rotate(-180deg)'
    },
    padding: ['6rem 1rem', '8rem 1rem'],
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },

    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  centerBlock2: {
    variant: 'customVariants.centerBlock',
    background: 'none',
    height: 'auto',
    color: 'black'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: '110vh',
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'inherit',
        textAlign: 'inherit'
      }
    },
    '.linksContainer': {
      mt: '2rem',
      justifyContent: 'center',
      alignItems: 'center'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/brick-wall-dark.png)',
    backgroundColor: 'primary',
    color: 'white',
    p: {
      color: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white'
    },
    img: {
      objectFit: 'contain'
    }
  },

  reservations: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: '10vh 10vw',
    '.textContent': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      mb: '7vh',
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['2rem', '2.25rem', '2.5rem', '2.5rem', '3rem'],
        borderBottom: '1px solid',
        mb: '2.5rem',
        color: 'primary'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontSize: ['1.25rem', '1.5rem', '1.75rem', '1.75rem', '2rem'],
        maxWidth: '900px',
        mb: '2.5rem',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        '::after': {
          ml: '1rem',
          content: "''",
          width: '200px',
          height: '200px',
          minWidth: '30%',
          display: 'flex',
          backgroundImage: 'url(https://restaurant.opentable.com/badge/ot/DC2-2023.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }
      },
      '.text': {
        variant: 'customVariants.text',
        maxWidth: '600px'
      }
    }
  },

  homepageSlider: {
    overflow: 'hidden'
  },

  contactCatering: {
    variant: 'customVariants.centerBlock',
    background: 'black'
  },

  homepageMenu: {
    variant: 'customVariants.centerBlock'
  },
  homepageLocation: {
    variant: 'customVariants.sideBySide1'
  },
  homepageEvents: {
    variant: 'customVariants.centerBlock'
  },
  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageContact: {
    variant: 'customVariants.centerBlock'
  },

  homepageHours: {
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/grunge-wall.png)',
    backgroundColor: 'white'
  },

  homepageShout: {
    backgroundColor: 'background',
    marginBottom: '3rem',
    padding: ['1rem', '', '2rem 1rem', '3rem 2rem 10rem'],
    // borderTop: '1px solid',
    // borderBottom: '1px solid',
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '3px solid',
      borderColor: 'black',
      fontFamily: 'subheading',
      width: ['', '', '80%'],
      color: 'primary',
      backgroundColor: 'transparent',
      letterSpacing: '0px'
    },
    '.text': {
      fontFamily: 'body',
      fontSize: ['1.75rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '0rem 2rem'],
      backgroundColor: ['#f3f3ea'],
      color: 'text',
      textTransform: 'capitalize',
      padding: ['2rem', '2rem'],
      marginTop: '3rem',
      width: ['', '', '55%']
    },
    '.date': {
      display: 'none', // ! We are hiding the shout date here.
      backgroundColor: 'secondary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'unset',
      color: 'white',
      flexGrow: '1',
      fontWeight: 'bold',
      justifyContent: 'center',
      position: 'relative',
      left: ['', '', '', '-3rem'],
      bottom: ['', '', '', '-2rem']
    },
    '.imageContainer': {
      position: 'relative',
      top: ['', '', '', '5rem'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
      flexGrow: '1'
    },

    margin: '0rem 0rem',
    '.shoutCTABtns ': {
      border: ['', '', '', 'solid 8px'],
      borderColor: ['', '', '', 'background'],
      flexDirection: ['row', 'row', 'row', 'column'],
      width: ['100%', '', '', 'auto'],
      a: {
        width: ['50%', '', '', '100%'],
        writingMode: ['unset', 'unset', 'unset', 'tb'],
        padding: '1rem',
        backgroundColor: 'transparent',
        color: 'primary',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        ':hover': {
          backgroundColor: 'primary',
          color: 'white'
        }
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  bio: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem',
    backgroundColor: '#e9e9e2',
    padding: '5rem 2rem 0rem',
    'div.content': {
      width: ['', '', '60%']
    },

    'div.lazyload-wrapper': {
      overflow: 'hidden',
      width: ['', '', '40%'],
      img: {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  // menu: {},
  menu: {
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '2.5rem'],
      textAlign: 'left',
      marginBottom: '1.5rem'
    },

    '.menuSectionDescription': {
      textAlign: 'left',
      marginBottom: '1.5rem',
      maxWidth: ['100%', '75%']
    },

    '.menuItemContainerImgActive': {
      height: '100%',
      '.menuItemInnerContainer': {
        flexDirection: ['column', '', 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        '.menuItemContentContainer': {
          width: ['100%', '', '', '70%'],
          '.menuItemName': {
            variant: 'customVariants.title',
            fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem']
            // width: ['60%', '75%'],
          },
          '.itemVariantsContainer': {
            display: 'flex',
            flexWrap: 'wrap'
          },
          '.variantsContainer': {
            display: 'flex',
            flexWrap: 'wrap',
            flexGrow: '1',
            margin: '0.5rem 1rem 0.5rem 0rem'
            // width: ['33.3%'],
          },
          '.variantContainer': {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            fontFamily: 'body2',
            backgroundColor: '#efefefba',
            padding: '0.75rem',
            borderRadius: '10px'
          },
          '.menuItemPriceLabel': {
            fontWeight: 'bold',
            fontSize: '0.8rem',
            marginBottom: '0.25rem'
          },
          '.variantContainerFloatTopRight': {
            fontSize: ['1.25rem', '1.5rem', '2rem']
          },
          '.menuItemLabelOptions': {
            fontSize: '0.7rem',
            maxWidth: '350px'
          },
          '.menuItemPriceVariants': {
            fontSize: '0.7rem',
            maxWidth: '350px'
          },
          '.menuItemDescription': {
            width: '100%',
            backgroundColor: 'white',
            opacity: '0.6',
            fontSize: '1rem',
            lineHeight: '1.5'
          }
        },

        '.menuItemImageContainer': {
          width: ['100%', '', '', '30%'],

          '.menuItemImg': {
            // objectPosition: 'top',
          }
        }
      }
    },
    '.menuItemPageContent': {
      '.itemVariantsContainer': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '.variantsContainer': {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '1',
        margin: '0.5rem 1rem 0.5rem 0rem'
        // width: ['33.3%'],
      },
      '.variantContainer': {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'body2',
        backgroundColor: '#efefefba',
        padding: '0.75rem',
        borderRadius: '10px'
      },
      '.menuItemPriceLabel': {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        marginBottom: '0.25rem'
      },
      '.variantContainerFloatTopRight': {
        fontSize: ['1.25rem', '1.5rem', '2rem']
      },
      '.menuItemLabelOptions': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemPriceVariants': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemDescription': {
        width: '100%',
        backgroundColor: 'white',
        opacity: '1',
        lineHeight: '1.7',
        padding: '1rem 0.25rem'
      }
    },
    '.menuItemButton': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField': {
      borderColor: 'white!important'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white!important',
      '::placeholder': {
        color: 'white!important'
      },
      '&:placeholder, :placeholder': {
        color: 'white!important'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '1.75rem', '1.75rem', '1.75rem', '1.75rem'],
      alignItems: 'center',
      color: 'white'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: 'lightgrey'
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ========================
  // ? ====  Success page  ====
  // ? ========================

  successFeedback: {
    height: '100vh',
    backgroundColor: 'black',
    '*': {
      color: 'white !important'
    },

    '.title': {
      order: 1,
      mb: '2rem'
    },
    '.subtitle': {
      order: 2,
      color: 'red !important',
      fontFamily: 'body'
    },
    '.text': {
      order: 3,
      maxWidth: '460px'
    }
  }
}
